import React from "react";
import {
  Page,
  Document,
    Image,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import moment from "moment";
import Logo from "../../../assets/img/logo_inta.png"
import ConvertFormat from "../../../helper/curency";

// import terbilang from 'angka-menjadi-terbilang'
const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    fontSize: 10,
    paddingTop: 30,
    paddingBottom: 20,

    lineHeight: 1.5,
    flexDirection: "column",
  },
  border: {
    borderWidth: 1,
    borderColor: "#00000",
    marginLeft: 40,
    marginRight: 40,
    padding:10
  },

  logo:{
      width:"91px",
      height:"47px",
      marginBottom:"10px"
  },

  title:{
    marginTop:"20px",
    marginBottom:"20px",
    textAlign:"center",
    textDecoration:"underline",
    fontFamily:'Helvetica-Bold',
    fontSize:"12px"
    // lineHeight:"30px"
  },

  header: {
    flexDirection: "row",
    paddingLeft: 30,
    paddingRight: 30,
  },
  
  infoHeader:{
    width: "100%", 
    // marginLeft: 10,
    // marginRight: 10,
    flexDirection: "row",
    justifyContent:"space-between",
    fontSize: 9,
    marginBottom:4
  },
  hr:{
    borderWidth: 0.5,
    borderColor: "#696969",
    marginTop:1,
    // marginBottom:5
  },
  infoInvoice:{
      marginTop:10,
      paddingLeft:10,
      paddingRight:10
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent:"center",
    marginTop:5,
    flexWrap:"wrap"
  },
  column:{
    flexDirection: "column",
    alignItems: "center",
    justifyContent:"center"
  },
  approved:{
    border:1,
    borderColor: "#000000",
    width:"33%",
    height:100,
    fontSize:9,
    alignItems: "center",
    justifyContent:"space-between",
    paddingTop:10,
    paddingRight:5,
    paddingBottom:10,
    paddingLeft:5,
  },
  


  
});

const PPPrintOut = ({ data }) => {
  console.log(data[0]);
  return (
    <Document
      subject={`Form PP-${moment().format("DD-MM-YYYY")}`}
      title={`Form PP-${moment().format("DD-MM-YYYY")}`}
    >
      <Page size="A4" style={styles.page}>
        <View style={styles.border}>
            <View>
                <Image style={styles.logo} src={Logo}/>
                <Text>{data[0].plantDescription}</Text>
                <Text>{data[0].paymentReqNo}</Text>
            </View>
            <Text style={styles.title}>PERMINTAAN PEMBAYARAN</Text>
            <View style={styles.infoHeader}>
                <Text>KEPADA : <span style={{textDecoration:"underline"}}>{data[0].to}</span></Text>
                <Text>DARI (CABANG/DEPT): <span style={{textDecoration:"underline"}}>{data[0].plantDescription !== null ? `${data[0].plantDescription}/Branch` :"-"}</span></Text>
                <Text>TANGGAL: <span style={{textDecoration:"underline"}}>{moment(data[0].createdDate).format("DD-MMM-YYYY")}</span></Text>
            </View>
            <View style={styles.hr}></View>
            <View style={styles.hr}></View>
            <View style={styles.infoInvoice}>
                <Text >{`MOHON DIBAYARKAN KEPADA : ${data[0].createdBy} `}</Text>
                <Text style={{marginTop:"15px"}}>{`JUMLAH SEBESAR: ${data[0].currency} ${ConvertFormat(data[0].totalAmountToBePaid)} `}</Text>
                <Text style={{marginTop:"15px"}}>{`PEMBAYARAN UNTUK (LAMPIRKAN DOKUMEN PENDUKUNG): ${data[0].vendorInvoiceNo} `}</Text>
                {/* <Text>PO.25</Text>
                <Text>DAILY CHECK</Text> */}
            </View>
            <View style={styles.hr}></View>
            <View style={styles.row} >      
                    <View style={styles.approved}>
                        <Text style={{fontSize:8}}>PERKIRAAN PEMBAYARAN PADA TGL:</Text>
                        <Text style={styles.approvedDate}>{moment(data[0].topDueDate).format("DD-MMM-YYYY")}</Text>
                    </View>
                    <View style={styles.approved}>
                        <View style={styles.column}>
                            <Text>Dibuat Oleh</Text>
                            <Text style={styles.approvedDate}>{`Date : ${moment(data[0].createdDate).format("DD-MMM-YYYY")}`}</Text>
                        </View>
                        <Text style={styles.approvedDate}>{data[0].createdBy}</Text>
                    </View>
                    {data[0].listApproved.map((d,i)=>{
                        return(
                            <View style={styles.approved} key={i}>
                                <View style={styles.column}>
                                    <Text>Approved By</Text>
                                    <Text style={styles.approvedDate}>{`Date : ${moment(d.approvalDate).format("DD-MMM-YYYY")}`}</Text>
                                </View>
                            <Text style={styles.approvedDate}>{d.executeBy}</Text>
                            </View>
                        )
                    })}
                    
                    
                   
            </View>
        </View>
        
      </Page>
    </Document>
  );
};

export default PPPrintOut;
