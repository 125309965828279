import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import Navbar from "../../../components/navBarVendor";
import Page from "../../../components/Page";
import Button from "../../../components/button";
import Toastify from 'toastify-js'
import "toastify-js/src/toastify.css"
import { Grid, Box,TextField } from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { DataGrid} from "@mui/x-data-grid";
import { changeScreenIS } from "../../../configs/redux/action/Vendor/invoiceSubmission";
import { changeScreenPO } from "../../../configs/redux/action/Vendor/purchaseOrder";
import {
  getTitle,
  getCountry,
  getCurrency,
  getPaymentMethods,
  getPaymentterms,
  getSysparam,
  getBankAccount,
  getListBank,
  updateProfile,
} from "../../../configs/redux/action/apiMaster";
import {getInfoVendor} from "../../../configs/redux/action/auth"
import {getItem} from "../../../configs/utilities/Storage"


const filter = createFilterOptions();
function EditProfile() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { listInfoVendor } = useSelector((state) => state.infoVendor);
  const {
    listTitle,
    listCurrency,
    listCountry,
    listPaymentTerms,
    listPaymentMethod,
    listSysparam,
    listBank,
    listBankAccount
  } = useSelector((state) => state.status);
  const [data, setData] = useState({
    id:listInfoVendor[0].id,
    titleType: listInfoVendor[0].titleType,
    name: listInfoVendor[0].name,
    vatRegistration: listInfoVendor[0].vatRegistration,
    companyTaxType: listInfoVendor[0].companyTaxType,
    city: listInfoVendor[0].city,
    countryCode: listInfoVendor[0].countryCode,
    postalCode: listInfoVendor[0].postalCode,
    street: listInfoVendor[0].street,
    telephone: listInfoVendor[0].telephone,
    faxNumber: listInfoVendor[0].faxNumber,
    emailAddress: listInfoVendor[0].emailAddress,
    salesPerson: listInfoVendor[0].salesPerson,
    salesPersonId: listInfoVendor[0].salesPersonId,
    pic: listInfoVendor[0].pic,
    currency: listInfoVendor[0].currency,
    termOfPayment: listInfoVendor[0].termOfPayment,
    paymentMethod: listInfoVendor[0].paymentMethod,
    listBankAccount:listBankAccount,
  });
  const [number,setNumber]=useState(1)
  const [removeId,setremoveId]=useState([])

  const [dropdownTitle, setTitle] = useState([]);
  const [dropdownCompanyTax, setCompanyTax] = useState([]);
  const [dropdownCountry, setCountry] = useState([]);
  const [dropdownCurrency, setCurrency] = useState([]);
  const [dropdownPaymentTerms, setPaymentTerms] = useState([]);
  const [dropdownPaymentMethod, setPaymentMethod] = useState([]);
  const [dropdownBank, setBank] = useState([]);

  const handleBack = () => {
    if (location.pathname === "/vdr/invoice-submission") {
      dispatch(changeScreenIS("list-is"));
    } else {
      dispatch(changeScreenPO("list-po"));
    }
  };

  const handleChange = (e) => {
    const dataNew = { ...data };
    dataNew[e.target.name] = e.target.value;
    setData(dataNew);
  };

  const [dataBankAccount, setdataBankAccount] = useState([]);

  const columnBankAccount = [
    {
      field: "bankCode",
      headerName: "Bank",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 200,
      renderCell: (cellValues) => {
        if(cellValues.row.newBank === false){
          return(
            <Autocomplete
              disablePortal
              id="bankCode"
              name="bankCode"
              onChange={(event, newValue) => {
                if (newValue !== null) {
                  const findIndexData =dropdownBank.findIndex((d)=> d.value.toLowerCase() === newValue["value"].toLowerCase())
                 
                  if(newValue["value"] !== "" && findIndexData < 0){
                    // jika data tidak ada di dropdown tambahkan input baru
                    const newData = dataBankAccount
                    const selected = newData.findIndex((d)=> d.id === cellValues.row.id)
                    newData[selected]["newBank"]=true
                    newData[selected]["bankCode"]=newValue["value"]
                    let detailTemp =[]
                    newData.forEach((d)=>{
                      detailTemp.push({
                        vendorCode:listInfoVendor[0].vendorCode,
                        bankCode:d.bankCode,
                        bankAccount:d.bankAccount,
                        bankAccountName: d.bankAccountName
                      })
                    })
                    setData({
                      ...data,
                      listBankAccount:detailTemp
                    })
                    setdataBankAccount(newData)
                  }else{
                    const newData = dataBankAccount
                    const selected = newData.findIndex((d)=> d.id === cellValues.row.id)
                    newData[selected]["bankCode"]=newValue["value"]
                    let detailTemp =[]
                    newData.forEach((d)=>{
                      detailTemp.push({
                        vendorCode:listInfoVendor[0].vendorCode,
                        bankCode:d.bankCode,
                        bankAccount:d.bankAccount,
                        bankAccountName: d.bankAccountName
                      })
                    })
                    setData({
                      ...data,
                      listBankAccount:detailTemp
                    })
                    setdataBankAccount(newData)
                  }

                }else{
                  const newData = dataBankAccount
                    const selected = newData.findIndex((d)=> d.id === cellValues.row.id)
                    // newData[selected]["newBank"]=true
                    newData[selected]["bankCode"]=null
                    let detailTemp =[]
                    newData.forEach((d)=>{
                      detailTemp.push({
                        vendorCode:listInfoVendor[0].vendorCode,
                        bankCode:d.bankCode,
                        bankAccount:d.bankAccount,
                        bankAccountName: d.bankAccountName
                      })
                    })
                    setData({
                      ...data,
                      listBankAccount:detailTemp
                    })
                    setdataBankAccount(newData)
                }
              }}
              options={dropdownBank}
              // open={openBankAccount}
              // onOpen={() => {
              //   setOpenBankAccount(true);
              // }}
              // onClose={() => {
              //   setOpenBankAccount(false);
              // }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                      const findIndexData =filtered.findIndex((d)=> d.value.toLowerCase() === params.inputValue.toLowerCase())
                      
                      if (params.inputValue !== "" && findIndexData < 0  ) {
                          filtered.push({
                            value: params.inputValue,
                            label: `Add "${params.inputValue}"`,
                          });
                        }
                        return filtered;
                    }}


              getOptionLabel={(option) => {
                
               if (typeof option === "string") {
                 return option;
               }
              //  if (option.value) {
              //   console.log(option);
              //    return option.value;
              //  }
               return option.label;
              }}
              size="small"
              value={cellValues.row.bankCode !== null && dropdownBank !== undefined && cellValues.row.bankCode !== "" ? cellValues.row.bankCode : null}
              isOptionEqualToValue={(option, value) =>option["value"].toLowerCase() === value.toLowerCase()}
              sx={{width: "100%" }}
              renderInput={(params) => (
                <TextField size="small" name="bankCode" {...params}  />
              )}
            />
          )
        }else{
          return(
            <TextField
                sx={{ mt: 1, width: "100%" }}
                id="bankCode"
                name="bankCode"
                size="small"
                value={cellValues.row.bankCode !== null && dropdownBank !== undefined && cellValues.row.bankCode !== "" ? cellValues.row.bankCode : null}
                onChange={(e)=>handleBankAccount(e,cellValues.row.id)}
              />
          )
        }
        
       
          // return (
          //   <select
          //         id="bankCode"
          //         name="bankCode"
          //         value={cellValues.row.bankCode}
          //         className="select-profile-bank"
          //         onChange={(e)=>handleBankAccount(e,cellValues.row.id)}
          //       >
          //         {dropdownBank.map((status, key) => (
          //           <option key={"titleType-" + key} value={status.value} hidden={status.value === "" ?true:false}>
          //             {status.label}
          //           </option>
          //         ))}
          //       </select>
          // );
        // }else{
        //   return cellValues.row.bankCode
        // }
      },
    },
    {
      field: "bankAccount",
      headerName: "Bank Account",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 437,
      renderCell: (cellValues) => {
        // if(cellValues.row.bankAccount === null){
          return (
            <input 
            id="bankAccount"
            name="bankAccount"
            className="input-profile-bank"
            value={cellValues.row.bankAccount}
            onChange={(e)=>handleBankAccount(e,cellValues.row.id)}
            />
          );
        // }else{
        //   return cellValues.row.bankAccount
        // }
      },
    },
    {
      field: "bankAccountName",
      headerName: "Bank Account Name",
      headerClassName: "super-app-theme--header",
      editable: false,
      width: 450,
      renderCell: (cellValues) => {
        // if(cellValues.row.bankAccountName === null){
          return (
            <input 
            id="bankAccountName"
            name="bankAccountName"
            className="input-profile-bank"
            value={cellValues.row.bankAccountName}
            onChange={(e)=>handleBankAccount(e,cellValues.row.id)}
            />
          );
        // }else{
        //   return cellValues.row.bankAccount
        // }
      },
    },
  ];



  const handleAddBtn = (number) => {
    number += 1;
    setNumber(parseInt(number))
    setdataBankAccount([
      ...dataBankAccount,
      {
        id: number,
        bankCode: null,
        bankAccount: null,
        bankAccountName: null,
        newBank:false
      },
    ]);
  };

  const handleBankAccount =(e,dataId)=>{
    const newData = dataBankAccount
    const selected = newData.findIndex((d)=> d.id === dataId)
    newData[selected][e.target.name]=e.target.value
    let detailTemp =[]
    newData.forEach((d)=>{
      detailTemp.push({
        vendorCode:listInfoVendor[0].vendorCode,
        bankCode:d.bankCode,
        bankAccount:d.bankAccount,
        bankAccountName: d.bankAccountName
      })
    })
    setData({
      ...data,
      listBankAccount:detailTemp
    })
    setdataBankAccount(newData)
  }

  const handleDelete =()=>{
    const id = removeId
    let filter;
    id.forEach((dataIds)=>{
      filter = dataBankAccount.filter((data)=>{
        return data.id !== dataIds
      })
    })
    setData({
      ...data,
      listBankAccount:filter
    })
    setdataBankAccount(filter)
  }
  
  const updateProfileVendor=()=>{
    dispatch(updateProfile(data)).then((res)=>{
      dispatch(getInfoVendor(`?UserId=${getItem("user_id")}`))
      dispatch(getBankAccount(listInfoVendor[0].vendorCode))
      if (location.pathname === "/vdr/invoice-submission") {
        dispatch(changeScreenIS("list-is"));
      } else {
        dispatch(changeScreenPO("list-po"));
      }
      
      Toastify({
        text: "Update Profile Success",
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: "top",
        position: "right",
        stopOnFocus: true,
        style: {
          background: "#5EBA7D",
        },
      }).showToast();
    }).catch((err)=>console.log(err))
  }

  useEffect(() => {
    let newDataTitle = [];
    let newDataTaxType = [];
    let newDataCountry = [];
    let newDataCurrency = [];
    let newDataPaymentTerms = [];
    let newDataPaymentMethod = [];
    let newDataBank = [];

    let titleData = listTitle;
    let taxTypeData = listSysparam;
    let countryData = listCountry;
    let currencyData = listCurrency;
    let termData = listPaymentTerms;
    let payMethodData = listPaymentMethod;
    let bankData = listBank

    const filterBank = bankData.filter((d)=>d.bankCode !== null)
    filterBank.forEach((data, i) => {
      newDataBank.push({
        value: data.bankCode,
        label: data.bankCode,
      });
    });

    titleData.forEach((data, i) => {
      newDataTitle.push({
        value: data.titleType,
        label: data.titleType,
      });
    });
    taxTypeData.forEach((data, i) => {
      newDataTaxType.push({
        value: data.value,
        label: `${data.value} - ${data.description}`,
      });
    });
    countryData.forEach((data, i) => {
      newDataCountry.push({
        value: data.countryCode,
        label: `${data.countryCode} - ${data.countryName}`,
      });
    });
    currencyData.forEach((data, i) => {
      newDataCurrency.push({
        value: data.currencyCode,
        label: `${data.currencyCode} - ${data.currencyDescription}`,
      });
    });

    termData.forEach((data, i) => {
      newDataPaymentTerms.push({
        value: data.paymentTerm,
        label: `${data.paymentTerm} - ${data.explanation}`,
      });
    });

    payMethodData.forEach((data, i) => {
      newDataPaymentMethod.push({
        value: data.paymentType,
        label: data.paymentDescription,
      });
    });
    setTitle(newDataTitle);
    setCompanyTax(newDataTaxType);
    setCountry(newDataCountry);
    setCurrency(newDataCurrency);
    setPaymentTerms(newDataPaymentTerms);
    setPaymentMethod(newDataPaymentMethod);
    setBank(newDataBank)
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(getListBank());
    dispatch(getSysparam("?ParamCode=COMPANY_TAX_TYPE"));
    dispatch(getPaymentterms());
    dispatch(getPaymentMethods());
    dispatch(getTitle());
    dispatch(getCountry());
    dispatch(getCurrency()); // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(getBankAccount(listInfoVendor[0].vendorCode)).then((res)=>{
      const newData = res.data
      newData.forEach((d)=>{
        d.newBank = false
      })
      setdataBankAccount(newData)
    })// eslint-disable-next-line
  }, [])
  
  return (
    <Page title="Profile">
      <Navbar />
      <div className="container-vendor">
        <div
          className="header-vendor"
          style={{
            backgroundColor: "#FFFFFF",
            borderBottom: "1px solid rgba(196, 210, 234, 0.6)",
          }}
        >
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              marginRight: "10px",
              marginLeft: "20px",
              height: 0,
              paddingBottom: "30px",
              justifyContent: "space-between",
            }}
          >
            <h6>Profile</h6>
            <div style={{
              flexDirection: "row",
              display: "flex",
            }}>
            <Button
              btn="btn-vendor-outlined"
              title="Back"
              onClick={handleBack}
            />
            <Button
              btn="btn-vendor-outlined"
              onClick={updateProfileVendor}
              title="Save"
            />
            </div>
          </div>
        </div>
        {/* registration info */}
        <div className="content-edit-profile">
          <div className="square">
            <h3>Registration Info</h3>
            <Grid container spacing={2}>
              <Grid className="label-input" item xs={2} md={2}>
                <label style={{ marginTop: "10px" }} htmlFor="VendorId">
                  Vendor Id
                </label>
                <label style={{ marginTop: "45px" }} htmlFor="PlantCode">
                  Plant Code
                </label>
                <label style={{ marginTop: "45px" }} htmlFor="companyGroup">
                  Company Group
                </label>
                <label style={{ marginTop: "45px" }} htmlFor="Porg">
                  POrg
                </label>
                <label style={{ marginTop: "45px" }} htmlFor="searchTerm">
                  Search Term
                </label>
              </Grid>
              <Grid item xs={8} md={8} className="label-input">
                <input
                  disabled={true}
                  style={{ backgroundColor: "#F3F3F3" }}
                  id="VendorId"
                  name="VendorId"
                  type="text"
                  defaultValue={listInfoVendor[0].vendorCode}
                />
                <input
                  disabled={true}
                  style={{ backgroundColor: "#F3F3F3" }}
                  id="PlantCode"
                  name="PlantCode"
                  type="text"
                  defaultValue={listInfoVendor[0].plantCode}
                />
                <input
                  disabled={true}
                  style={{ backgroundColor: "#F3F3F3" }}
                  id="companyGroup"
                  name="companyGroup"
                  type="text"
                  defaultValue={listInfoVendor[0].companyCode}
                />
                <input
                  disabled={true}
                  id="Porg"
                  style={{ backgroundColor: "#F3F3F3" }}
                  name="Porg"
                  type="text"
                  defaultValue={listInfoVendor[0].purchaseOrganization}
                />
                <input
                  disabled={true}
                  style={{ backgroundColor: "#F3F3F3" }}
                  id="searchTerm"
                  name="searchTerm"
                  type="text"
                  defaultValue={listInfoVendor[0].termOfPayment}
                />
              </Grid>
            </Grid>
          </div>
        </div>
        {/* end registration info */}

        {/* start vendor & tax info */}
        <div className="content-edit-profile">
          <div className="square">
            <h3>Vendor & Tax Info</h3>
            <Grid container spacing={2}>
              <Grid className="label-input" item xs={2} md={2}>
                <label style={{ marginTop: "10px" }} htmlFor="titleType">
                  Title
                </label>
                <label style={{ marginTop: "45px" }} htmlFor="name">
                  Name
                </label>
                <label style={{ marginTop: "45px" }} htmlFor="vatRegistration">
                  VAT Registration No
                </label>
                <label style={{ marginTop: "45px" }} htmlFor="companyTaxType">
                  Company Tax Type
                </label>
                <label style={{ marginTop: "45px" }} htmlFor="country">
                  Country
                </label>
                <label style={{ marginTop: "45px" }} htmlFor="city">
                  City
                </label>
                <label style={{ marginTop: "45px" }} htmlFor="postalCode">
                  Postal Code
                </label>
                <label style={{ marginTop: "45px" }} htmlFor="street">
                  Street
                </label>
              </Grid>
              <Grid item xs={8} md={8} className="label-input">
                <select
                  id="titleType"
                  name="titleType"
                  value={data.titleType}
                  onChange={handleChange}
                >
                  {dropdownTitle.map((status, key) => (
                    <option key={"titleType-" + key} value={status.value}>
                      {status.label}
                    </option>
                  ))}
                </select>
                <input
                  id="name"
                  name="name"
                  type="text"
                  value={data.name}
                  onChange={handleChange}
                />
                <input
                  id="vatRegistration"
                  name="vatRegistration"
                  type="text"
                  value={data.vatRegistration}
                  onChange={handleChange}
                />
                <select
                  id="companyTaxType"
                  name="companyTaxType"
                  value={data.companyTaxType}
                  onChange={handleChange}
                >
                  {dropdownCompanyTax.map((status, key) => (
                    <option key={"companyTaxType-" + key} value={status.value}>
                      {status.label}
                    </option>
                  ))}
                </select>
                <select
                  id="countryCode"
                  name="countryCode"
                  value={data.countryCode}
                  onChange={handleChange}
                >
                  {dropdownCountry.map((status, key) => (
                    <option key={"countryCode-" + key} value={status.value}>
                      {status.label}
                    </option>
                  ))}
                </select>
                <input
                  id="city"
                  name="city"
                  type="text"
                  value={data.city}
                  onChange={handleChange}
                />
                <input
                  id="postalCode"
                  name="postalCode"
                  type="text"
                  value={data.postalCode}
                  onChange={handleChange}
                />
                <textarea
                  id="street"
                  name="street"
                  type="text"
                  value={data.street}
                  onChange={handleChange}
                  style={{ height: "79px", paddingTop: "10px" }}
                />
              </Grid>
            </Grid>
          </div>
        </div>
        {/* end vendor & tax info */}

        {/*start contact info  */}
        <div className="content-edit-profile">
          <div className="square">
            <h3>Contact Info</h3>
            <Grid container spacing={2}>
              <Grid className="label-input" item xs={2} md={2}>
                <label style={{ marginTop: "10px" }} htmlFor="telephone">
                  Telephone
                </label>
                <label style={{ marginTop: "45px" }} htmlFor="faxNumber">
                  Fax Number
                </label>
                <label style={{ marginTop: "45px" }} htmlFor="emailAddress">
                  Email
                </label>
                <label style={{ marginTop: "45px" }} htmlFor="salesPerson">
                  Sales Person
                </label>
                <label style={{ marginTop: "45px" }} htmlFor="salesPersonId">
                  Sales Person ID
                </label>
                <label style={{ marginTop: "45px" }} htmlFor="pic">
                  PIC
                </label>
              </Grid>
              <Grid item xs={8} md={8} className="label-input">
                <input
                  id="telephone"
                  name="telephone"
                  type="text"
                  value={data.telephone}
                  onChange={handleChange}
                />
                <input
                  id="faxNumber"
                  name="faxNumber"
                  type="text"
                  value={data.faxNumber}
                  onChange={handleChange}
                />
                <input
                  id="emailAddress"
                  name="emailAddress"
                  type="text"
                  value={data.emailAddress}
                  onChange={handleChange}
                />
                <input
                  id="salesPerson"
                  name="salesPerson"
                  type="text"
                  value={data.salesPerson}
                  onChange={handleChange}
                />
                <input
                  id="salesPersonId"
                  name="salesPersonId"
                  type="text"
                  value={data.salesPersonId}
                  onChange={handleChange}
                  disabled={true}
                  style={{
                    backgroundColor: ( true) ? "#F2F2F2" : "white",
                  }}
                />
                <input
                  id="pic"
                  name="pic"
                  type="text"
                  value={data.pic}
                  onChange={handleChange}
                  disabled={true}
                  style={{
                    backgroundColor: ( true) ? "#F2F2F2" : "white",
                  }}
                />
              </Grid>
            </Grid>
          </div>
        </div>
        {/* end contact info */}

        {/* start payment info */}
        <div className="content-edit-profile">
          <div className="square">
            <h3>Payment Info</h3>
            <Grid container spacing={2}>
              <Grid className="label-input" item xs={2} md={2}>
                <label style={{ marginTop: "10px" }} htmlFor="currency">
                  Currency
                </label>
                <label style={{ marginTop: "45px" }} htmlFor="termOfPayment">
                  Payment Term
                </label>
                <label style={{ marginTop: "45px" }} htmlFor="paymentMethod">
                  Payment Method
                </label>
              </Grid>
              <Grid item xs={8} md={8} className="label-input">
                <select
                  id="currency"
                  name="currency"
                  value={data.currency}
                  onChange={handleChange}
                >
                  {dropdownCurrency.map((status, key) => (
                    <option key={"currency-" + key} value={status.value}>
                      {status.label}
                    </option>
                  ))}
                </select>
                <select
                  id="termOfPayment"
                  name="termOfPayment"
                  value={data.termOfPayment}
                  onChange={handleChange}
                >
                  {dropdownPaymentTerms.map((status, key) => (
                    <option key={"termOfPayment-" + key} value={status.value}>
                      {status.label}
                    </option>
                  ))}
                </select>
                <select
                  id="paymentMethod"
                  name="paymentMethod"
                  value={data.paymentMethod}
                  onChange={handleChange}
                >
                  {dropdownPaymentMethod.map((status, key) => (
                    <option key={"paymentMethod-" + key} value={status.value}>
                      {status.label}
                    </option>
                  ))}
                </select>
              </Grid>
            </Grid>
          </div>
        </div>
        {/* end payment info */}

        {/* start bank info */}
        <div className="content-edit-profile">
          <div className="square">
            <h3>Bank Info</h3>
            <div
            style={{
              flexDirection: "row",
              display: "flex",
              marginLeft: "20px",
              marginBottom:"20px",
              justifyContent: "flex-end",
            }}
          >
           
            <Button  btn="btn-vendor-outlined"
              disabled={removeId.length === 0 && true}
              title="Delete Account"
              color={removeId.length === 0 ? "white":"red"}
              onClick={handleDelete}
            />
            <Button
            btn="btn-vendor-outlined"
            title="Add Account"
            color="blue-sky"
              // sx={{ width: "130px", marginRight: "20px" }}
              // variant="outlined"
              onClick={() =>
                handleAddBtn(parseInt(number))
              }
            />
            
          </div>
            <Box
                      sx={{
                        height: 300,
                        width: "100%",
                        "& .super-app-theme--header": {
                          backgroundColor: "#001D61",
                          color: "#FFFFFF",
                          fontFamily: "Quicksand",
                          fontSize: "12px",
                          fontWeight: "bold",
                        },
                      }}
                    >
                      <DataGrid
                        sx={{
                          "& .MuiDataGrid-cell:hover": {
                            color: "primary.main",
                          },
                          // "& .MuiDataGrid-cell": {
                          //   fontFamily: "Quicksand",
                          //   fontSize: "14px",
                          //   fontWeight: 500,
                          //   color: "#333333",
                          // },
                        }}
                        checkboxSelection
                        pageSize={10}
                        columns={columnBankAccount}
                        disableSelectionOnClick
                        rows={dataBankAccount}
                        onSelectionModelChange={(id) => {
                          const selectedIDs = new Set(id);
                          const selectedRowData = dataBankAccount.filter((row) =>
                          selectedIDs.has(row.id)
                          );
                          if (selectedRowData.length >= 1) {
                            let newId = [];
                            selectedRowData.forEach((data, i) => {
                              newId.push(data.id)
                            });
                            setremoveId(newId)
                          } else {
                            setremoveId([])
                          }
                        }}
                      />
                    </Box>
          </div>
        </div>
        {/* end bank info */}
      </div>
    </Page>
  );
}

export default EditProfile;
