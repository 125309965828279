import React from 'react'
import { BrowserRouter, Redirect, Switch } from 'react-router-dom'

// Routing component
import {RouteWithSubRoutes} from './routeWithSubRoutes';
// import {RoutePrivate} from './routePrivate';
import {RoutePrivateVendor} from './routePrivateVendor'

// Icon
// import HomeIcon from "@mui/icons-material/Home";

// import Home from '../../pages/main/home'
import Signup from '../../pages/auth/signupVendor'
import Login from '../../pages/auth/loginVendor'
// import LoginUser from '../../pages/auth/loginUser';
// import PortalAuth from '../../pages/auth/portal'

// vendor
import InvoiceSubmission from '../../pages/main/vendor/invoiceSubmission';
import PurchaseOrder from '../../pages/main/vendor/purchaseOrder';

// export const mainRoutes = [
//     {
//         component: Home,
//         icon: HomeIcon,
//         path: '/',
//         title: 'Home',
//         type: 'route'
//     },
    
// ];

export const authRoutes = [
    
    {
        component: Signup,
        path: '/auth/vdr/register',
        title: 'Sign Up',
        type: 'route'
    },
    {
        component: Login,
        path: '/auth/vdr/login',
        title: 'Login',
        type: 'route'
    },
    {
        from: '/auth',
        to: '/auth/vdr/login',
        type: 'redirect'
    },
    
];
export const vendorRoute = [
    
    {
        component:PurchaseOrder ,
        path: '/',
        title: 'Purchase Order',
        type: 'route'
    },
    {
        component:PurchaseOrder ,
        path: '/vdr/purchase-order',
        title: 'Purchase Order',
        type: 'route'
    },
    {
        component: InvoiceSubmission,
        path: '/vdr/invoice-submission',
        title: 'Invoice Submission',
        type: 'route'
    },
    // {
    //     from: '/',
    //     to: '/vdr/purchase-order',
    //     type: 'redirect'
    // },
];
function MainRoute() {
    return (
        <BrowserRouter>
            <Switch>
                {
                    vendorRoute.map((rute, id) => (
                        rute.type === 'route' ? (<RoutePrivateVendor exact key={"rute-main-" + id} {...rute} />) : rute.type === 'redirect' ? (<Redirect key={"redirect-main-" + id} from={rute.from} to={rute.to} />) : null
                    ))
                }
                {
                    authRoutes.map((rute, id) => (
                        rute.type === 'route' ? (<RouteWithSubRoutes exact key={"rute-auth-" + id} {...rute} />) : rute.type === 'redirect' ? (<Redirect key={"redirect-auth-" + id} from={rute.from} to={rute.to} />) : null
                    ))
                }
            </Switch>
        </BrowserRouter>
    )
}

export default MainRoute