import { RequestGet,RequestPost,RequestPostAuth } from "../../utilities";

export const getInfoVendor = (query) => (dispatch) => {
    return new Promise((resolve, reject) => {
        if (query !== undefined) {
            RequestGet("UserVendor/UserVendorSearch"+query)
              .then((res) => {
                dispatch({
                  type: "LIST_INFO_VENDOR",
                  payload: res.data,
                });
                resolve(res);
              })
              .catch((err) => {
                reject(err);
              });
        }else{
            RequestGet("UserVendor/UserVendorSearch")
              .then((res) => {
                dispatch({
                  type: "LIST_INFO_VENDOR",
                  payload: res.data,
                });
                resolve(res);
              })
              .catch((err) => {
                reject(err);
              });
        }
    });
  };

  export const createVendor = (data) => (dispatch) => {
    return new Promise((resolve, reject) => {
            RequestPost("VendorFrontOffice/CreateVendor",data)
              .then((res) => {
                resolve(res);
              })
              .catch((err) => {
                reject(err);
              });
        
    });
  };

export const loginAuth = (data) => (dispatch) => {
    return new Promise((resolve, reject) => {
        RequestPostAuth("connect/token",data)
            .then(res =>{
                // dispatch({
                //     type: 'DATA_AUTH', 
                //     payload: res.data
                // });
                resolve(res);
            })
            .catch(err => {
                reject(err);
            })
    });
};

export const getUser = () => (dispatch) => {
    return new Promise((resolve, reject) => {
        RequestGet(process.env.REACT_APP_URL_AUTH + "connect/userinfo")
            .then(res =>{
                // dispatch({
                //     type: 'DATA_AUTH', 
                //     payload: res.data
                // });
                resolve(res);
            })
            .catch(err => {
                reject(err);
            })
    });
};