import './App.css';

import { Provider } from 'react-redux'
// import store from './configs/redux/store'
import { HelmetProvider } from 'react-helmet-async';
import Route from './configs/router/mainRoute'
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./configs/reduxPersist/configureStore";


function App() {
  return (
    <Provider store={store} >
      <PersistGate loading={null} persistor={persistor}>
        <HelmetProvider>
          <Route />
        </HelmetProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;