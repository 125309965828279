import React, {Fragment} from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';
import Convert from "../../../helper/curency"

const borderColor = '#000'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        textAlign: 'center',
        height: 24,
        fontStyle: 'bold',
        fontSize:9.5,
    },
    width10: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    width15: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    width40: {
        width: '40%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'left',

    },
    width20: {
        width: '20%',
        textAlign: 'left',

    },
    width30: {
        width: '30%',
        textAlign: 'left',

    },

    width80: {
        width: '80%',
        textAlign:"left",
    },
    width90:{
        width: '90%',
        textAlign:"left",
        marginRight:"10px"
    },
    amount: {
        width: '30%',
        textAlign: 'right',
    },

    width20WithoutBorder: {
        width: '20%',
    },
    description: {
        width: '60%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    qty: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    rate: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    
    column:{
        flexDirection: 'column',
    },
  });



const DataTable = ({items}) => {
    const newData = items;
        newData.forEach(function (element,index) {
            element.id=index+1
        });

    const rows = newData.map((data,index) => (
        <View style={styles.row} key={data.id}>
            <Text style={styles.width20}>{data.item}</Text>
            <Text style={styles.width90}>{data.material}</Text>
            <Text style={styles.width30}>{data.quantity}</Text>
            <Text style={styles.width20}>{data.oum}</Text>
            <Text style={styles.width30}>{data.currency === "IDR"?Convert(data.netValue):data.netValue}</Text>
            <Text style={styles.amount}>{data.currency === "IDR"?Convert(data.amount):data.amount}</Text>
        </View>
    ))
    return (<Fragment>{rows}</Fragment> )
};
  
  export default DataTable