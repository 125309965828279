import { RequestGet,RequestPost,RequestPostAuth } from "../../utilities";
import moment from "moment";

export const getStatus = (query) => (dispatch) => {
    return new Promise((resolve, reject) => {
        if (query !== undefined) {
            RequestGet("StatusMapping/Status"+query)
              .then((res) => {
                dispatch({
                  type: "LIST_STATUS",
                  payload: res.data,
                });
                resolve(res);
              })
              .catch((err) => {
                reject(err);
              });
        }else{
            RequestGet("StatusMapping/Status")
              .then((res) => {
                dispatch({
                  type: "LIST_STATUS",
                  payload: res.data,
                });
                resolve(res);
              })
              .catch((err) => {
                reject(err);
              });
        }
    });
  };

  export const getSysparam = (query) => (dispatch) => {
    return new Promise((resolve, reject) => {
        if (query !== undefined) {
            RequestGet("SysParam/SysParamSearch"+query)
              .then((res) => {
                dispatch({
                  type: "LIST_SYSPARAM",
                  payload: res.data,
                });
                resolve(res);
              })
              .catch((err) => {
                reject(err);
              });
        }else{
            RequestGet("SysParam/SysParamSearch")
              .then((res) => {
                dispatch({
                  type: "LIST_SYSPARAM",
                  payload: res.data,
                });
                resolve(res);
              })
              .catch((err) => {
                reject(err);
              });
        }
    });
  };

  export const getTitle = () => (dispatch) => {
    return new Promise((resolve, reject) => {
          RequestGet("Title/TitleSearch")
            .then((res) => {
              dispatch({
                type: "LIST_TITLE_DRW",
                payload: res.data,
              });
              resolve(res);
            })
            .catch((err) => {
              reject(err);
            });
    });
  };
  
  export const getCountry = () => (dispatch) => {
    return new Promise((resolve, reject) => {
          RequestGet("Country/CountrySearch")
            .then((res) => {
              dispatch({
                type: "LIST_COUNTRY_DRW",
                payload: res.data,
              });
              resolve(res);
            })
            .catch((err) => {
              reject(err);
            });
    });
  };

  export const getCurrency = () => (dispatch) => {
    return new Promise((resolve, reject) => {
          RequestGet("Currency/CurrencySearch")
            .then((res) => {
              dispatch({
                type: "LIST_CURR_DRW",
                payload: res.data,
              });
              resolve(res);
            })
            .catch((err) => {
              reject(err);
            });
    });
  };
  
  export const getPaymentterms = () => (dispatch) => {
    return new Promise((resolve, reject) => {
          RequestGet("PaymentTerm/PaymentTermSearch")
            .then((res) => {
              dispatch({
                type: "LIST_PAYMENT_TERMS_DRW",
                payload: res.data,
              });
              resolve(res);
            })
            .catch((err) => {
              reject(err);
            });
    });
  };

  export const getPaymenttermsDueDate = (paymentTerm) => (dispatch) => {
    return new Promise((resolve, reject) => {
          RequestGet(`PaymentTerm/PaymentTermSearch?PaymentTerm=${paymentTerm}`)
            .then((res) => {
              let days = res.data[0].days
              let date = new Date();
              let last = new Date(date.getTime() + (days * 24 * 60 * 60 * 1000));
              let formatDate = moment(last).format("yyyy-MM-DD")
              dispatch({
                type: "LIST_PAYMENT_TERMS_DUEDATE",
                payload: formatDate,
              });
              resolve(res);
            })
            .catch((err) => {
              reject(err);
            });
    });
  };


  export const getPaymentMethods = () => (dispatch) => {
    return new Promise((resolve, reject) => {
          RequestGet("PaymentMethod/PaymentMethodSearch")
            .then((res) => {
              dispatch({
                type: "LIST_PAYMENT_METHOD_DRW",
                payload: res.data,
              });
              resolve(res);
            })
            .catch((err) => {
              reject(err);
            });
    });
  };

  export const getBankAccount = (data) => (dispatch) => {
    return new Promise((resolve, reject) => {
          RequestGet("BankAccount/BankAccountSearch?VendorCode="+data)
            .then((res) => {
              dispatch({
                type: "LIST_BANK_ACCOUNT",
                payload: res.data,
              });
              resolve(res);
            })
            .catch((err) => {
              reject(err);
            });
    });
  };

  export const getListBank = () => (dispatch) => {
    return new Promise((resolve, reject) => {
          RequestGet("Bank/BankSearch")
            .then((res) => {
              dispatch({
                type: "LIST_BANK",
                payload: res.data,
              });
              resolve(res);
            })
            .catch((err) => {
              reject(err);
            });
    });
  };
  
  export const getCompanyCode = () => (dispatch) => {
    return new Promise((resolve, reject) => {
          RequestGet("Company/CompanySearch")
            .then((res) => {
              dispatch({
                type: "LIST_COMPANY_CODE",
                payload: res.data,
              });
              resolve(res);
            })
            .catch((err) => {
              reject(err);
            });
    });
  };

  export const updateProfile = (data) => (dispatch) => {
    return new Promise((resolve, reject) => {
            RequestPost("VendorFrontOffice/UpdateVendorProfile",data)
              .then((res) => {
                resolve(res);
              })
              .catch((err) => {
                reject(err);
              });
        
    });
  };

  export const changePasswordVendor = (data) => (dispatch) => {
    return new Promise((resolve, reject) => {
        RequestPostAuth("connect/changepassword",data)
            .then(res =>{
                // dispatch({
                //     type: 'DATA_AUTH', 
                //     payload: res.data
                // });
                resolve(res);
            })
            .catch(err => {
                reject(err);
            })
    });
};

export const getPIC = () => (dispatch) => {
  return new Promise((resolve, reject) => {
        RequestGet("VendorFrontOffice/PiCInfo")
          .then((res) => {
            let getData = res.data;
                let ddlPIC = [{ value: "", label: "Choose Here" }];

                if (getData.length >= 1) {
                    getData.forEach((data, i) => {
                        ddlPIC.push({
                        value: data.userId,
                        label: data.userName,
                        });
                    });
                }
            dispatch({
              type: "LIST_PIC_INFO",
              payload: ddlPIC,
            });
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
  });
};