const initialState = {
  screen: "list-is",
  selectedISVendor: null,
  listPo: [],
  itemlist:[],
  itemInvoice:[],
  dropdownWht:[],
  createInvo:[],
  listSupportDoc:[],
  listItemType:[],
  detailInvoice:[],
  jurnal:[],
  listUploadItemList:[]
};

const isReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHANGE_SCREEN_IS":
      return {
        ...state,
        screen: action.payload,
      };
    case "CHANGE_SELECTED_IS":
      return {
        ...state,
        selectedISVendor: action.payload,
      };
    case "LIST_PO":
      return {
        ...state,
        listPo: action.payload,
      };
      case "ITEM_LIST_PO":
        return {
          ...state,
          itemlist: action.payload,
        };
        case "ITEM_LIST_IS":
        return {
          ...state,
          itemInvoice: action.payload,
        };
        case "DROPDOWN_WHT":
        return {
          ...state,
          dropdownWht: action.payload,
        };
      case "CREATE_INVOICE":
        return {
          ...state,
          createInvo: action.payload,
        };
        case "LIST_SUPPORT_DOC":
        return {
          ...state,
          listSupportDoc: action.payload,
        };
        case "LIST_ITEM_TYPE":
        return {
          ...state,
          listItemType: action.payload,
        };
        
        case "LIST_DETAIL":
        return {
          ...state,
          detailInvoice: action.payload,
        };
        case "LIST_JURNAL":
        return {
          ...state,
          jurnal: action.payload,
        };
        case "LIST_ITEM_LIST_UPLOAD":
        return {
          ...state,
          listUploadItemList: action.payload,
        };
        
    default:
      return state;
  }
};

export default isReducer;
