import NumberFormat from "react-number-format";
import {
    Grid, 
    Tooltip,
    IconButton
} from "@mui/material";
import moment from "moment";

import MoreVertIcon from "@mui/icons-material/MoreVert";

export const listPurchaseOrderColumn = (listStatus, handleDetail,setAnchorEl,setSelectedPo,setOpenOptions) => ([
    {
        field: "purchaseNo",
        headerName: "PO Number",
        headerClassName: "super-app-theme--header",
        editable: false,
        width: 200,
        renderCell: (cellValues) => {
          return (
            <Grid container spacing={2} 
            >
              <Grid item xs={8}>
                <p
                style={{cursor: "pointer",color: "#2F80ED", textDecoration: "underline" }}
                onClick={() => 
                  {
                    handleDetail(cellValues.row.purchaseNo, cellValues.row.id)
                  }
                }
                >
                  {cellValues.row.purchaseNo}
                </p>
              </Grid>
              <Grid item xs={4}  style={{display:"flex", justifyContent:"end"}}>
              <IconButton
                id={"opt-more-gl-" + cellValues.row.pr_id}
                onClick={(event) => {
                  setAnchorEl(event.currentTarget);
                  setSelectedPo(cellValues.row.purchaseNo)
                  setOpenOptions(true);
                }}
              >
                <MoreVertIcon style={{color:"#333333", fontSize:"1.25rem"}} />
              </IconButton>
              </Grid>
                
            </Grid>
            
          );
        },
  
      },
      {
        field: "purchaseDescription",
        headerName: "PO Description",
        headerClassName: "super-app-theme--header",
        editable: false,
        flex:0.3,
        minWidth: 120,
        renderCell: (cellValues) =>  {
          return(
            <Tooltip title={cellValues.row.purchaseDescription} >
              <span className="table-cell-trucate">{cellValues.row.purchaseDescription}</span>
            </Tooltip>
          )
        }
      },
      {
        field: "sapCreatedOn",
        headerName: "PO Date",
        headerClassName: "super-app-theme--header",
        editable: false,
        width: 150,
        renderCell:(cellValues)=>{
          if (cellValues.row.sapCreatedOn !== null) {
            let date = new Date(cellValues.row.sapCreatedOn);
            if (date !== null && date !== undefined) {
              return moment(date).format("DD MMM YYYY")
            }
          }
        }
      },
      {
        field: "poType",
        headerName: "PO Type",
        headerClassName: "super-app-theme--header",
        editable: false,
        width: 150,
        renderCell:(cellValues)=>{
          switch (cellValues.row.poType) {
            case 1:return <p>Trade</p>
            case 0:return <p>Non Trade</p>
            default:return null
          }
        }
      },
      {
        field: "companyCode",
        headerName: "CoCd",
        headerClassName: "super-app-theme--header",
        editable: false,
        width: 100,
      },
      {
        field: "incotermCode",
        headerName: "Incoterms",
        headerClassName: "super-app-theme--header",
        editable: false,
        width: 120,
      },
      {
        field: "approvalStatus",
        headerName: "Status",
        headerClassName: "super-app-theme--header",
        editable: false,
        width: 165,
        renderCell: (cellValues) => {
          let dataStatus = listStatus
          let findData = dataStatus.find(d => parseInt(d.statusCode)  === cellValues.row.approvalStatus && d.category === "Approval_PO_Non_Trade" );
          return findData.statusDescription
        },
      },
])

export const detailItemList = () => ([
    {
        field: "item",
        headerName: "Item",
        headerClassName: "super-app-theme--header",
        editable: false,
        width: 60,
      },
      {
        field: "purchaseNo",
        headerName: "PO Number",
        headerClassName: "super-app-theme--header",
        editable: false,
        width: 200,
      },
      {
        field: "material",
        headerName: "Material",
        headerClassName: "super-app-theme--header",
        editable: false,
        width: 120,
      },
      {
        field: "shortText",
        headerName: "Short Text (Item)",
        headerClassName: "super-app-theme--header",
        editable: false,
        flex:1,
        minwidth: 160,
      },
      {
        field: "oum",
        headerName: "Oum",
        headerClassName: "super-app-theme--header",
        editable: false,
        width: 90,
      },
      {
        field: "quantity",
        headerName: "Qty",
        headerClassName: "super-app-theme--header",
        editable: false,
        width: 80,
      },
      {
        field: "netPrice",
        headerName: "Net Price",
        headerClassName: "super-app-theme--header",
        editable: false,
        width: 120,
        renderCell: (cellValues) => {
          if(cellValues.row.netPrice !== null && cellValues.row.netPrice !== undefined){
            return <NumberFormat displayType="text" decimalScale={2} thousandSeparator="." decimalSeparator="," value={cellValues.row.netPrice} />
          }
        },
      },
      {
        field: "itemStatus",
        headerName: "Item Status",
        headerClassName: "super-app-theme--header",
        editable: false,
        width: 160,
      },
      {
        field: "awbInvoiceNo",
        headerName: "AWB/INV NO",
        headerClassName: "super-app-theme--header",
        editable: false,
        width: 140,
      },
      {
          field: "grStatus",
          headerName: "GR Status",
          headerClassName: "super-app-theme--header",
          editable: false,
          width: 150,
        },
]);