import {RequestGet,RequestPut} from "../../../utilities/APIConnect"
import uuid from "react-uuid";

export const changeScreenPO = (screen) => (dispatch) => {
    return dispatch({
        type: 'CHANGE_SCREEN_PO',
        payload: screen
    })
}

export const changeSelectedPO = (data) => (dispatch) => {
    return dispatch({
        type: 'CHANGE_SELECTED_PO',
        payload: data
    })
}

export const getListPO = (query) => (dispatch) => {
    return new Promise((resolve, reject) => {
      RequestGet("POTradeFrontOffice/PurchaseOrder"+query)
        .then((res) => {
        const data = res.data;
        data.forEach(function (element) {
          element.id = uuid();
          element.reviseNote=null
        });
          dispatch({
            type: "LIST_PO_MENU",
            payload: data,
          });
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  export const getDetailPO = (query) => (dispatch) => {
    return new Promise((resolve, reject) => {
      RequestGet("POTradeFrontOffice/DetailPO"+query)
        .then((res) => {
          console.log(res.data);
          dispatch({
            type: "LIST_DETAIL_PO_MENU",
            payload: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };


  export const updatePOStatus = (data) => (dispatch) => {
    return new Promise((resolve, reject) => {
      RequestPut("POTradeFrontOffice/UpdatePO",data)
        .then((res) => {
          dispatch({
            type: "UPDATE_PO",
            payload: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  export const getPrintOut = (query) => (dispatch) => {
    return new Promise((resolve, reject) => {
      RequestGet("POTradeFrontOffice/DownloadPO"+query)
        .then((res) => {
          dispatch({
            type: "LIST_DETAIL_PRINT_PO",
            payload: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  