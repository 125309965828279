import { useSelector } from 'react-redux';

// sub component PR
import ManagePO from './listPO'
import DetailPO from './detailPO'
import EditProfile from '../index'

function FormPp(){
    const {screen} = useSelector((state) => state.vendorPo);
    const displayScreen = () => {
        switch(screen){
            case 'edit-profile':
                return <EditProfile />;
            case 'list-po':
                return <ManagePO />;
            case 'detail-po':
                return <DetailPO />;
            default:
                return <ManagePO />;
        }
    }

    return(
        <div>
            { displayScreen() }
        </div>
    );
}

export default FormPp;