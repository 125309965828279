import { useSelector } from 'react-redux';

// sub component PR
import ManageInvoice from './listInvoiceSubmission';
import CreateInvoice from './createInvoice'
import EditProfile from '../index'
import DetailInvoice from './detailInvoice';

function FormPp(){
    const {screen} = useSelector((state) => state.vendorInvoiceSubmission);
    const displayScreen = () => {
        switch(screen){
            case 'edit-profile':
                return <EditProfile />;
            case 'list-is':
                return <ManageInvoice />;
            case 'create-is':
                return <CreateInvoice />;
            case 'detail-is':
                return <DetailInvoice />;
            default:
                return <ManageInvoice />;
        }
    }

    return(
        <div>
            { displayScreen() }
        </div>
    );
}

export default FormPp;