import React from 'react';
import { BrowserRouter as Router, Route,Redirect } from "react-router-dom";
import { getItem } from "../utilities/Storage";

const RemoveWarning = route => (
    <Router></Router>
);
const RoutePrivateVendor = route => {
    const isAuthentication = getItem("access_token");
    const role = getItem("GR");
    return (
    <div>
        <Route
            state={route.title}
            path={route.path}
            render={props => (
                isAuthentication && role.toLowerCase() === "vendor" ?<route.component {...props} routes={route.routes}/>:<Redirect to="/auth/vdr/login"/>
            )}
        />
    </div>
);
}
export { RoutePrivateVendor, RemoveWarning };