import React, { useState,useEffect } from "react";
import { useDispatch,useSelector } from "react-redux";

// component
import Navbar from "../../../../components/navBarVendor";
import Page from "../../../../components/Page";
import Button from "../../../../components/button";
import PPPrintOut from "../../../../components/pdf/ppPrinout/ppPrintOut";
import CustomDataGrid from "../../../../components/table";


// package
import { Backdrop, Box,Stack,Dialog,DialogActions,DialogTitle,DialogContent,DialogContentText,CircularProgress ,Fade,IconButton, ListItemIcon,ListItemText,Menu,MenuItem, Grid,} from "@mui/material";
import moment from "moment";
import Swal from "sweetalert2"
import { PDFViewer} from "@react-pdf/renderer";
import NumberFormat from "react-number-format";

// Icons
// import InfoIcon from "@mui/icons-material/Info";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import MoreVertIcon from "@mui/icons-material/MoreVert";

// redux
import { changeScreenIS,getItemListInvoice,deleteInvoice,detailInvoice,getSupportDoc,getItemType, changeSelectedIS, exportFormPP, } from "../../../../configs/redux/action/Vendor/invoiceSubmission";
import {getSysparam,getBankAccount,getCompanyCode} from "../../../../configs/redux/action/apiMaster"

// icon,css,img
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Folder from "../../../../assets/img/folder.png"
import Toastify from "toastify-js";
import "../vendor.css";
import "toastify-js/src/toastify.css";
import { useHistory } from "react-router-dom";

function NoRowsOverlay() {
  return (
    <Stack height="100%"  alignItems="center" justifyContent="center">
      <img src={Folder} width="128" heigh="64" alt="Data Not Found"/>
    </Stack>
  );
}

function InvoiceSubmission() {
  const history = useHistory();
  const dispatch = useDispatch()
  const { itemInvoice,selectedISVendor } = useSelector((state) => state.vendorInvoiceSubmission);
  
  const { listStatus,listBankAccount} = useSelector((state) => state.status);
  const { listInfoVendor} = useSelector((state) => state.infoVendor);
 
  const [anchorEl, setAnchorEl] = useState(null);

  const [pageSize, setPageSize] = useState(10)
  const [paymentReqNo,setPaymentReqNo]=useState([])

  const [loading,setLoading]=useState(false)
  const [loadingChangePage, setLoadingChangePage] = useState(false);
  const [openViewNote,setOpenViewNote]=useState(false)
  const [dataReviseNote,setDataReviseNote]=useState(null)
  const [openOptions, setOpenOptions] = useState(false);
  const [dataPdf,setDataPdf]= useState([])
  const [openPdf, setPDF] = useState(false);

  const theColumns = [
    {
      field: "paymentReqNo",
      headerName: "Payment Request No",
      headerClassName: 'super-app-theme--header',
      editable: false,
      width: 200,
      renderCell: (cellValues) => {
        return (
          <Grid container spacing={2} 
          >
            <Grid item xs={8}>
              <p
              style={{cursor: "pointer",color: "#2F80ED", textDecoration: "underline" }}
              onClick={() => handleDetail(cellValues.row.paymentReqNo,cellValues.row.invoiceStatus,listInfoVendor[0].countryCode,cellValues.row.id)
              }
              >
                {cellValues.row.paymentReqNo}
              </p>
            </Grid>
            <Grid item xs={4}  style={{display:"flex", justifyContent:"end"}}>
            <IconButton
              id={"opt-more-gl-" + cellValues.row.pr_id}
              onClick={(event) => {
                setAnchorEl(event.currentTarget);
                dispatch(changeSelectedIS(cellValues.row))
                setOpenOptions(true);
              }}
            >
              <MoreVertIcon style={{color:"#333333", fontSize:"1.25rem"}} />
            </IconButton>
            </Grid>
          </Grid>
        );
      },
    },
    {
      field: "vendorInvoiceNo",
      headerName: "Vendor Invoice No",
      headerClassName: 'super-app-theme--header',
      editable: false,
      flex:1,
      minwidth: 180,
      description:"vendor invoice"
    },
    {
      field: "taxType",
      headerName: "Tax Type",
      headerClassName: 'super-app-theme--header',
      editable: false,
      flex:1,
      minwidth: 140,
      renderCell: (cellValues) => {
        switch (cellValues.row.taxType) {
          case "Taxable":
            return <p>Taxable</p>;
          case "nonTaxable":
            return <p>Non Taxable</p>;
          default:
            return <p></p>;
        }
      },
    },
    {
      field: "dueDate",
      headerName: "Due Date",
      headerClassName: 'super-app-theme--header',
      editable: false,
      flex: 1,
      minwidth: 140,
      renderCell:(cellValues)=>{
        if (cellValues.row.dueDate !== null) {
          let date = new Date(cellValues.row.dueDate);
          if (date !== null && date !== undefined) {
            return moment(date).format("DD MMM YYYY")
          }
        }
      }
    },
    {
      field: "totalAmountToBePaid",
      headerName: "Amount To Be Paid",
      headerClassName: 'super-app-theme--header',
      editable: false,
      width: 180,
      renderCell: (cellValues) => {
        if(cellValues.row.totalAmountToBePaid !== null && cellValues.row.totalAmountToBePaid !== undefined){
          return <NumberFormat displayType="text" decimalScale={2} thousandSeparator="." decimalSeparator="," value={cellValues.row.totalAmountToBePaid} />
        }
      },
    },
    {
      field: "invoiceStatus",
      headerName: "Status",
      headerClassName: 'super-app-theme--header',
      editable: false,
      width: 165,
      renderCell: (cellValues) => {
          let dataStatus = listStatus
          let findData = dataStatus.find(d => parseInt(d.statusCode)  === cellValues.row.invoiceStatus && d.category === "Approval_Invoice" );
          return findData.statusDescription
      },
    },
    {
      field: "pendingBy",
      headerName: "Pending By",
      headerClassName: 'super-app-theme--header',
      editable: false,
      width: 165,
    },
    {
      field: "isReceivedDoc",
      headerName: "Received All Documents",
      headerClassName: 'super-app-theme--header',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      width: 170,
      renderCell: (cellValues) => {
        if(cellValues.row.isReceivedDoc === 1){
            return <CheckCircleOutlineIcon color="success" />;
        }else{
            return "";
        }
    },
    },
    {
      field: "approvalNotes",
      headerName: "Revise Note",
      headerClassName: 'super-app-theme--header',
      editable: false,
      width: 100,
      renderCell: (cellValues) => {
        if (cellValues.row.approvalNotes !== null && cellValues.row.approvalNotes !== undefined ) {
          return <p onClick={()=>{
            setOpenViewNote(true)
            setDataReviseNote(cellValues.row.approvalNotes)
          }} style={{cursor:"pointer"}}>View Note</p>;
        } else {
          return <p></p>;
        }
      },
    },
    {
      field: "createdDate",
      headerName: "Created Date",
      headerClassName: 'super-app-theme--header',
      editable: false,
      width: 165,
      renderCell:(cellValues)=>{
        if (cellValues.row.createdDate !== null) {
          let date = new Date(cellValues.row.createdDate);
          if (date !== null && date !== undefined) {
            return moment(date).format("DD MMM YYYY")
          }
        }
      }
    },
  ];

  const renderOption = (
    <Menu
      anchorEl={anchorEl}
      id="option"
      open={openOptions}
      onClose={() => {
        setAnchorEl(null);
        setOpenOptions(false);
      }}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      TransitionComponent={Fade}
    >
      <MenuItem
        onClick={() => {
          setAnchorEl(null);
          setOpenOptions(false);
          dispatch(exportFormPP(selectedISVendor.paymentReqNo)).then((res)=>{
            setDataPdf(res.data)
            setPDF(true)
          });
        }}
      >
        <ListItemIcon>
          <PictureAsPdfIcon />
        </ListItemIcon>
        <ListItemText>Download Form PP</ListItemText>
      </MenuItem>
    </Menu>
  );

  const handleCreate=()=>{
    const newDataItemList = itemInvoice

    const findDraftRevised = newDataItemList.filter((d)=>{
      return d.invoiceStatus === 0 || d.invoiceStatus === 4
    })

    if(listBankAccount.length <= 0 ){
      return Toastify({
        text: "Please complete your bank account in profile!",
        duration: 5000,
        newWindow: true,
        close: true,
        gravity: "top",
        position: "right",
        stopOnFocus: true,
        style: {
          background: "#CB605A",
        },
      }).showToast();
    }
    if (findDraftRevised.length < 1) {
      setLoading(true)
      dispatch(getSupportDoc(listInfoVendor[0].countryCode))
      dispatch(getBankAccount(listInfoVendor[0].vendorCode))
      dispatch(getItemType())
      dispatch(getSysparam("?ParamCode=VAT_RATE"))
      dispatch(getCompanyCode())// eslint-disable-next-line

      setTimeout(() => {
        dispatch(changeScreenIS("create-is"))
      }, 2000);
    }else{
      Toastify({
        text: "Please submit or delete the payment request with status draft or revised before create new Payment Request",
        duration: 5000,
        newWindow: true,
        close: true,
        gravity: "top",
        position: "right",
        stopOnFocus: true,
        style: {
          background: "#CB605A",
        },
      }).showToast();
    }
  }

  const handleDetail=(data,status,countryCode,id)=>{
    setLoadingChangePage(true);
    dispatch(detailInvoice(data,status,countryCode)).then((res)=>{
      if(status === 0 || status === 4){
        dispatch(getSysparam("?ParamCode=VAT_RATE"))
      }
      dispatch(getBankAccount(listInfoVendor[0].vendorCode))
    }).then(() => {
      history.push(`/vdr/invoice-submission?Id=${id}`)
      dispatch(changeScreenIS("detail-is"))
    })
  }
  const handleDelete=()=>{
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteInvoice(paymentReqNo.toString())).then((res) => {
          setPaymentReqNo([])
          Swal.fire(
            'Deleted!',
            'Your invoice has been deleted.',
            'success'
          )
          dispatch(getItemListInvoice(`?VendorCode=${listInfoVendor[0].vendorCode}`));
        }).catch((err)=>{
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
          })
          console.log(err);
        })
      }
    })
    
  }

  useEffect(() => {
    dispatch(getItemListInvoice(`?VendorCode=${listInfoVendor[0].vendorCode}`));
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, [])

  return (
    <Page title="Invoice Submission">
      <Navbar />
      {renderOption}

      <div className="container-vendor">
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loadingChangePage}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="header-vendor">
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <h6>Invoice Submission</h6>
              <p>List Invoice that have been submitted to Intraco</p>
            </Grid>
            <Grid item xs={8}
             style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
            >
              <Button  btn="btn-vendor-outlined"
                disabled={paymentReqNo.length === 0 && true}
                title="Delete Invoice"
                color={paymentReqNo.length === 0 ? "white":"red"}
                onClick={handleDelete}
              />
              {loading !== false?
              <div className="btn-for-vendor-outlined">
                <Button
                  btn="btn-download-loader"
                />
              </div>
                :
              <Button
                btn="btn-vendor-outlined"
                title="Create New"
                color="blue-sky"
                onClick={handleCreate}
              />
              }
            </Grid>
          </Grid>
        </div>
        <div className="content-list-ipms">
        <Box
            sx={{
              height:640,
              // width: 1,
              "& .super-app-theme--header": {
                  height: "100px",

                backgroundColor: "#001D61",
                color: "#FFFFFF",
                fontFamily: "Quicksand",
                fontSize: "12px",
                fontWeight: "bold",
              },
            }}
          >
            <CustomDataGrid
              checkboxSelection
              columns={theColumns}
              components={{ NoRowsOverlay }}
              disableSelectionOnClick
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              isRowSelectable={(params) =>(params.row.invoiceStatus === 0 || params.row.invoiceStatus === 4 ||params.row.invoiceStatus === 5 )}
              rows={itemInvoice}
              rowsPerPageOptions={[5, 10, 25, 100]}
              onSelectionModelChange={(id) => {
                const selectedIDs = new Set(id);
                const selectedRowData = itemInvoice.filter((row) =>
                  selectedIDs.has(row.id)
                );
                if (selectedRowData.length >= 1) {
                  let newPoNo = []
                  selectedRowData.forEach((data,i)=>{
                    newPoNo.push(data.paymentReqNo)
                  })
                  setPaymentReqNo(newPoNo)
                } else {
                  setPaymentReqNo([])
                }
              }}
            />
          </Box>
        </div>
      </div>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        // fullScreen={fullScreen}
        open={openViewNote}
        onClose={()=>setOpenViewNote(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Revise Note</DialogTitle>
        <DialogContent dividers>
          <DialogContentText className="dialog">
            <p>{dataReviseNote}</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button btn="btn-vendor-outlined"
              title="Close"
              color="red" onClick={()=>setOpenViewNote(false)}/>
        </DialogActions>
      </Dialog>
      <Dialog maxWidth={"lg"} open={openPdf} onClose={()=>setPDF(false)}>
          <DialogContent>
            <PDFViewer width="1000" height="600">
              <PPPrintOut data={dataPdf} />
            </PDFViewer>
          </DialogContent>
        </Dialog>
    </Page>
  );
}

export default InvoiceSubmission;
