import React from "react";
import { BrowserRouter as Router, Route,Redirect } from "react-router-dom";
import { getItem } from "../utilities/Storage";

const RemoveWarning = (route) => <Router></Router>;

const RouteWithSubRoutes = (route) => {
  const isAuthentication = getItem("access_token");
  const role = getItem("GR");

  return (
    <div>
      <Route
        state={route.title}
        path={route.path}
        render={props => (
            isAuthentication && role.toLowerCase() === "vendor" ?<Redirect to="/"/>:<route.component {...props} routes={route.routes} />
        )}
        // render={(props) => <route.component {...props} routes={route.routes} />}
      />
    </div>
  );
};

export { RouteWithSubRoutes, RemoveWarning };
