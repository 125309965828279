import React, {useState} from 'react'
import {Dialog,DialogTitle,DialogContent,DialogActions,DialogContentText,Grid} from "@mui/material"

import Button from './button'
// import * as Yup from "yup";
import {changePasswordVendor} from "../configs/redux/action/apiMaster"
import { useDispatch} from "react-redux";
import {getItem} from "../configs/utilities/Storage"
import Toastify from "toastify-js";
import "toastify-js/src/toastify.css";

function ChangePassword(props) {
    const dispatch = useDispatch();
    const [loading,setLoading]= useState(false)
    const [errMsg,setErrMsg]=useState([])

    const [data,setData]=useState({
        currentPassword:"",
        newPassword:"",
        confirmPassword:""
    })
    const handleChange = (e) => {
        const dataNew = { ...data };
        dataNew[e.target.name] = e.target.value;
        setData(dataNew);
      };

    
    const handleSubmit=()=>{
        setLoading(true)
        let userInfo = JSON.parse(getItem("user_info"))
        const params = new URLSearchParams();
          params.append('name',getItem("name"));
          params.append('email',userInfo.email);
          params.append('currentpassword',data.currentPassword);
          params.append('newpassword',data.newPassword);
          params.append('confirmpassword',data.confirmPassword);
        //   props.open(false)
          dispatch(changePasswordVendor(params)).then((res)=>{
            setLoading(false)
            setErrMsg([])
            setData({
                currentPassword:"",
                newPassword:"",
                confirmPassword:""
            })
            Toastify({
                text: "Success",
                duration: 3000,
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                style: {
                  background: "#5EBA7D",
                },
              }).showToast();
            }).catch((err)=>{
            setLoading(false)
                setErrMsg(err.data.messages)
                console.log(err.data.messages);
            })
        
    }
    
  return (
    <Dialog
            fullWidth={true}
            open={props.open}
            onClose={props.close}
          >
            <DialogTitle>Change Password</DialogTitle>
            <DialogContent dividers>
            <DialogContentText className="dialog-changePass">
            <Grid container spacing={2}>
              <Grid className="label-dialog" item xs={4} md={4}>
                <label htmlFor="currentPassword" style={{marginTop:"5px"}}>Current Password</label>
                <label htmlFor="newPassword">New Password</label>
                <label htmlFor="confirmPassword">Confirm Password</label>

              </Grid>
              <Grid className="label-dialog" item xs={8} md={8}>
                <input type="password" id="currentPassword" name="currentPassword" value={data.currentPassword} onChange={handleChange} />
                <input type="password" id="newPassword" name="newPassword" value={data.newPassword} onChange={handleChange}/>
                <input type="password" id="confirmPassword" name="confirmPassword" value={data.confirmPassword} onChange={handleChange} />
              </Grid>
            </Grid>
            {errMsg.length >= 1 && errMsg.map((data)=>{
                return (
                    <ul className='list-error'>
                        <li>{data}</li>
                    </ul>
                )
            })}
          </DialogContentText>
            </DialogContent>
           <DialogActions>
                    <Button
                        onClick={props.close}
                        btn="btn-dialog-pass"
                        title="Cancel"
                        color="blue-sky"
                    />
                    {loading === false ? (
                        <Button onClick={handleSubmit}  btn="btn-dialog-pass" title="Submit" color="blue-sky-bg" />
                    ) : (
                        <div className="submit-button">
                            <div className="loaderDownload"></div>
                        </div>
                    )}
        </DialogActions>
          </Dialog>
  )
}

export default ChangePassword