import { combineReducers } from 'redux';

import listStatusReducer from './apiMaster';
import uploadFileReducer from './uploadFile';
import invoiceSubReducer from './Vendor/invoiceSubmission';
import povendorReducer from './Vendor/purchaseOrder';
import infoVendorReducer from './auth';

const rootReducer = combineReducers({
    uploadFile: uploadFileReducer,
    vendorInvoiceSubmission:invoiceSubReducer,
    vendorPo:povendorReducer,
    status:listStatusReducer,
    infoVendor:infoVendorReducer
})

export default rootReducer